/**
 * @author pxdraft
 * @version 1.0
 *
 */
(function ($) {
    "use strict";
    var MOMB = {};
    $.fn.exists = function () {
        return this.length > 0;
    };

    /* ---------------------------------------------- /*
     * Pre load
    /* ---------------------------------------------- */
    MOMB.PreLoad = function () {
        // document.getElementById("loading").style.display = "none";
    }

    /*--------------------
      * Menu toogle header
    ----------------------*/
    MOMB.MenuToggleClass = function () {
        $('.navbar-toggler').on('click', function () {
            var toggle = $('.navbar-toggler').is(':visible');
            if (toggle) {
                $('header').toggleClass('header-toggle');
            }
        })
    }

    /* ---------------------------------------------- /*
     * Header Fixed
    /* ---------------------------------------------- */
    MOMB.HeaderFixd = function () {
        var HscrollTop = $(window).scrollTop();
        var HHeight = $('.header-height').outerHeight()
        var HHeightTop = $('.header-top').outerHeight()

        if (HscrollTop >= 80) {
            $(".navbar-dark").addClass("navbar-light");
            $(".navbar-dark").addClass("navbar-dark-top");
            $(".navbar-dark-top").removeClass("navbar-dark");
            $(".header-main").addClass("fixed-header");
            $('.header-main').css("top", - HHeightTop);
        } else {
            $(".navbar-dark-top").removeClass("navbar-light");
            $(".navbar-dark-top").addClass("navbar-dark");
            $(".navbar-dark").removeClass("navbar-dark-top");
            $(".header-main").removeClass("fixed-header");
            $('.header-main').css("top", 0);
        }
    }


    /* ---------------------------------------------- /*
     * Header height
    /* ---------------------------------------------- */
    MOMB.HeaderHeight = function () {
        var HHeight = $('.header-height').outerHeight()
        var HHeightTop = $('.header-top').outerHeight()
        $('.header-height-bar').css("min-height", HHeight);
    }

    /* ---------------------------------------------- /*
     * Mega Menu
    /* ---------------------------------------------- */

    MOMB.MegaMenu = function () {
        var mDropdown = $(".px-dropdown-toggle")
        mDropdown.on("click", function () {
            $(this).parent().toggleClass("open-menu-parent");
            $(this).next('.dropdown-menu').toggleClass("show");
            $(this).toggleClass("open");
        });
    }

    /*--------------------
    * Counter
    ----------------------*/
    MOMB.Counter = function () {
        //var counter = jQuery(".counter");
        var $counter = $('.counter');
        if ($counter.length > 0) {
            $counter.each(function () {
                var $elem = $(this);
                $elem.appear(function () {
                    $elem.find('.count').countTo({
                        speed: 2000,
                        refreshInterval: 10
                    });
                });
            });
        }
    }

    /*--------------------
    * Typed
    ----------------------*/
    // MOMB.typedbox = function () {
    //     var typedjs = $('.typed');
    //     if (typedjs.length > 0) {
    //         typedjs.each(function () {
    //             var $this = $(this);
    //             $this.typed({
    //                 strings: $this.attr('data-elements').split(','),
    //                 typeSpeed: 150, // typing speed
    //                 backDelay: 500 // pause before backspacing
    //             });
    //         });
    //     }
    // }

    /*--------------------
    * Owl Corousel
    ----------------------*/
    // MOMB.Owl = function () {
    //     var owlslider = $("div.owl-carousel");
    //     if (owlslider.length > 0) {
    //         owlslider.each(function () {
    //             var $this = $(this),
    //                 $items = ($this.data('items')) ? $this.data('items') : 1,
    //                 $loop = ($this.attr('data-loop')) ? $this.data('loop') : true,
    //                 $navdots = ($this.data('nav-dots')) ? $this.data('nav-dots') : false,
    //                 $navarrow = ($this.data('nav-arrow')) ? $this.data('nav-arrow') : false,
    //                 $autoplay = ($this.attr('data-autoplay')) ? $this.data('autoplay') : true,
    //                 $autospeed = ($this.attr('data-autospeed')) ? $this.data('autospeed') : 5000,
    //                 $smartspeed = ($this.attr('data-smartspeed')) ? $this.data('smartspeed') : 1000,
    //                 $autohgt = ($this.data('autoheight')) ? $this.data('autoheight') : false,
    //                 $CenterSlider = ($this.data('center')) ? $this.data('center') : false,
    //                 $stage = ($this.attr('data-stage')) ? $this.data('stage') : 0,
    //                 $space = ($this.attr('data-space')) ? $this.data('space') : 30;

    //             $(this).owlCarousel({
    //                 loop: $loop,
    //                 items: $items,
    //                 responsive: {
    //                     0: {
    //                         items: $this.data('xs-items') ? $this.data('xs-items') : 1
    //                     },
    //                     576: {
    //                         items: $this.data('sm-items') ? $this.data('sm-items') : 1
    //                     },
    //                     768: {
    //                         items: $this.data('md-items') ? $this.data('md-items') : 1
    //                     },
    //                     992: {
    //                         items: $this.data('lg-items') ? $this.data('lg-items') : 1
    //                     },
    //                     1200: {
    //                         items: $items
    //                     }
    //                 },
    //                 dots: $navdots,
    //                 autoplayTimeout: $autospeed,
    //                 smartSpeed: $smartspeed,
    //                 autoHeight: $autohgt,
    //                 center: $CenterSlider,
    //                 margin: $space,
    //                 stagePadding: $stage,
    //                 nav: $navarrow,
    //                 navText: ["<i class='bi bi-chevron-left'></i>", "<i class='bi bi-chevron-right'></i>"],
    //                 autoplay: $autoplay,
    //                 autoplayHoverPause: true
    //             });
    //         });
    //     }
    // }

    /* ---------------------------------------------- /*
     * lightbox gallery
    /* ---------------------------------------------- */
    // MOMB.Gallery = function () {
    //     var GalleryPopup = $('.lightbox-gallery');
    //     if (GalleryPopup.length > 0) {
    //         $('.lightbox-gallery').magnificPopup({
    //             delegate: '.gallery-link',
    //             type: 'image',
    //             tLoading: 'Loading image #%curr%...',
    //             mainClass: 'mfp-fade',
    //             fixedContentPos: true,
    //             closeBtnInside: false,
    //             gallery: {
    //                 enabled: true,
    //                 navigateByImgClick: true,
    //                 preload: [0, 1] // Will preload 0 - before current, and 1 after MOMB current image
    //             }
    //         });
    //     }
    //     var VideoPopup = $('.video-btn');
    //     if (VideoPopup.length > 0) {
    //         $('.video-btn').magnificPopup({
    //             disableOn: 700,
    //             type: 'iframe',
    //             mainClass: 'mfp-fade',
    //             removalDelay: 160,
    //             preloader: false,
    //             fixedContentPos: false
    //         });
    //     }
    // }

    /*--------------------
    * Masonry
    ----------------------*/
    // MOMB.masonry = function () {
    //     var portfolioWork = $('.portfolio-content');
    //     if (portfolioWork.length > 0) {
    //         $(portfolioWork).isotope({
    //             resizable: false,
    //             itemSelector: '.grid-item',
    //             layoutMode: 'masonry',
    //             filter: '*'
    //         });
    //         //Filtering items on portfolio.html
    //         var portfolioFilter = $('.filter li');
    //         // filter items on button click
    //         $(portfolioFilter).on('click', function () {
    //             var filterValue = $(this).attr('data-filter');
    //             portfolioWork.isotope({
    //                 filter: filterValue
    //             });
    //         });
    //         //Add/remove class on filter list
    //         $(portfolioFilter).on('click', function () {
    //             $(this).addClass('active').siblings().removeClass('active');
    //         });
    //     }
    // }

    /*--------------------
        * Progress Bar 
    ----------------------*/
    MOMB.ProgressBar = function () {
        $(".skill-bar .skill-bar-in").each(function () {
            var bottom_object = $(this).offset().top + $(this).outerHeight();
            var bottom_window = $(window).scrollTop() + $(window).height();
            var progressWidth = $(this).attr('aria-valuenow') + '%';
            if (bottom_window > bottom_object) {
                $(this).css({
                    width: progressWidth
                });
            }
        });
    }
    /*-----------------------
    * SVG
    -------------------------*/
    var mySVGsToInject = document.querySelectorAll('.svg_img, .svg_icon');
    MOMB.SVGbx = function () {
        if (mySVGsToInject.length > 0) {
            SVGInjector(mySVGsToInject);
        }
    }

    /*--------------------
       * pieChart
   ----------------------*/
    // MOMB.pieChart = function () {
    //     var $Pie_Chart = $('.pie_chart_in');
    //     if ($Pie_Chart.length > 0) {
    //         $Pie_Chart.each(function () {
    //             var $elem = $(this),
    //                 pie_chart_size = $elem.attr('data-size') || "160",
    //                 pie_chart_animate = $elem.attr('data-animate') || "2000",
    //                 pie_chart_width = $elem.attr('data-width') || "6",
    //                 pie_chart_color = $elem.attr('data-color') || "#84ba3f",
    //                 pie_chart_track_color = $elem.attr('data-trackcolor') || "rgba(0,0,0,0.10)",
    //                 pie_chart_line_Cap = $elem.attr('data-lineCap') || "round",
    //                 pie_chart_scale_Color = $elem.attr('data-scaleColor') || "true";
    //             $elem.find('span, i').css({
    //                 'width': pie_chart_size + 'px',
    //                 'height': pie_chart_size + 'px',
    //                 'line-height': pie_chart_size + 'px',
    //                 'position': 'absolute'
    //             });
    //             $elem.appear(function () {
    //                 $elem.easyPieChart({
    //                     size: Number(pie_chart_size),
    //                     animate: Number(pie_chart_animate),
    //                     trackColor: pie_chart_track_color,
    //                     lineWidth: Number(pie_chart_width),
    //                     barColor: pie_chart_color,
    //                     scaleColor: false,
    //                     lineCap: pie_chart_line_Cap,
    //                     onStep: function (from, to, percent) {
    //                         $elem.find('span.middle').text(Math.round(percent));
    //                     }
    //                 });
    //             });
    //         });
    //     }
    // }


    /*--------------------
        * count down
    ----------------------*/

    MOMB.CountTimer = function () {
        var $count_timer = $('.count-down');
        var regionalVar = { days: 'Days', day: 'Day', years: 'Years', year: 'Year', hours: 'Hours', hour: 'Hour', minutes: 'Minutes', minute: 'Minute', seconds: 'Seconds', second: 'Second' };
        if ($count_timer.length > 0) {
            $('.count-down').countdown({ regional: regionalVar });
        }
    }

    /*--------------------
        * Wow
    ----------------------*/
    MOMB.wowAni = function () {
        new WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 100,
            mobile: !1,
            live: !0
        }).init();
    }

    /*--------------------
        * One Page
    ----------------------*/
    MOMB.one_page = function () {
        //var HHeight = $('.navbar').outerHeight();
        var $one_page_nav = $('.one-page-nav');
        if ($one_page_nav.length > 0) {
            $one_page_nav.each(function () {
                $.scrollIt({
                    upKey: 38,             // key code to navigate to the next section
                    downKey: 40,           // key code to navigate to the previous section
                    easing: 'linear',      // the easing function for animation
                    scrollTime: 600,       // how long (in ms) the animation takes
                    activeClass: 'active', // class given to the active nav element
                    onPageChange: null,    // function(pageIndex) that is called when page is changed
                    topOffset: -70           // offste (in px) for fixed top navigation
                });
            });
        }
    }

    /*--------------------
        * Pricing Plans
    ----------------------*/
    // MOMB.pricing_plans = function () {
    //     const pricingFeaturesList = $('#skillo-pricing-features-list');
    //     const pricingPlanPremiumSelector = $('#pricing-premium-selection-trigger');
    //     const pricingPlanFreeSelector = $('#pricing-free-selection-trigger');

    //     const enablePremiumFeatures = function () {
    //         if (pricingFeaturesList.hasClass('free')) {
    //             pricingFeaturesList.removeClass('free');
    //         }
    //     };

    //     const disablePremiumFeatures = function () {
    //         if (!pricingFeaturesList.hasClass('free')) {
    //             pricingFeaturesList.addClass('free');
    //         }
    //     };

    //     pricingPlanPremiumSelector.on('click', function () {
    //         if (!pricingPlanPremiumSelector.hasClass('btn-primary')) {
    //             pricingPlanPremiumSelector.addClass('btn-primary');
    //         }
    //         pricingPlanFreeSelector.removeClass('btn-primary');
    //         enablePremiumFeatures();
    //     });

    //     pricingPlanFreeSelector.on('click', function () {
    //         if (!pricingPlanFreeSelector.hasClass('btn-primary')) {
    //             pricingPlanFreeSelector.addClass('btn-primary');
    //         }
    //         pricingPlanPremiumSelector.removeClass('btn-primary');
    //         disablePremiumFeatures();
    //     });
    // }

    /*--------------------
        * Pricing Calculator
    ----------------------*/
    MOMB.pricing_calculator = function () {
        const $pricingSlider = $('#pricing-slider');

        if ($pricingSlider.length) {
            const pricingSlider = $pricingSlider.get(0);
            const pricingURL = $pricingSlider.data('pricing-url');

            const pricingFields = {
                shiftHoursPerMonth: $('#pricingShiftHoursPerMonth'),
                perShiftHour: $('#pricingPerShiftHour'),
                perMonth: $('#pricingPerMonth')
            };

            const updatePricingField = function (pricingData) {
                if (pricingData.statusCode === 200) {
                    const supplierCostBreakdown = pricingData.supplierCostBreakdownOut;
                    if (supplierCostBreakdown['billableHourPrice'] !== undefined) {
                        pricingFields.perShiftHour.html(supplierCostBreakdown['billableHourPrice'] + 'p');
                    }
                    if (supplierCostBreakdown['totalPrice'] !== undefined) {
                        pricingFields.perMonth.html('&pound;' + supplierCostBreakdown['totalPrice']);
                    }
                }
            };

            const pricingParams = {
                base: 50,
                maxShiftsInSlider: 50000
            };

            const recalculatePricing = function (hours) {

                // $.post(pricingURL, {input: hours}).done(function (data) {
                //     updatePricingField(data);
                // });
                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    url: pricingURL,
                    cache: false,
                    data: JSON.stringify({ input: hours }),
                    success: function (data) {
                        if (data.statusCode === 200) {
                            updatePricingField(data);
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.error("Failed to get the pricing");
                    }
                })
            };

            noUiSlider.create(pricingSlider, {
                behaviour: 'tap',
                start: Number(pricingFields.shiftHoursPerMonth.val()),
                step: 1000,
                range: {
                    min: 0,
                    max: pricingParams.maxShiftsInSlider
                }
            });

            pricingSlider.noUiSlider.on('update', function (
                values,
                handle,
                unencoded,
                isTap,
                positions
            ) {
                const shiftHoursPerMonth = Number(values[handle]);
                pricingFields.shiftHoursPerMonth.val(shiftHoursPerMonth);
                recalculatePricing(shiftHoursPerMonth);
            });

            recalculatePricing(pricingFields.shiftHoursPerMonth.val());

            pricingFields.shiftHoursPerMonth.change(function (e) {
                const shiftHoursPerMonth = Number(pricingFields.shiftHoursPerMonth.val());
                recalculatePricing(shiftHoursPerMonth);
                pricingSlider.noUiSlider.set(shiftHoursPerMonth);
            });
        }
    }

    MOMB.contactForm = function () {

        const $contactForm = $('form.rd-mailform');
        const $formNotify = $contactForm.find('.form-notify');
        let formUrl = $contactForm.attr('action');

        $contactForm.validate({
            onclick: false,
            onfocusout: false,
            onkeyup: false,
            ignore: '.ignore',
            rules: {
                name: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                telephoneNumber: {
                    required: true
                },
                company: {
                    required: true
                },
                message: {
                    required: true
                },
                agree: {
                    required: true
                }
            },
            errorPlacement: function (error, element) {
            },
            highlight: function (element) {
                $(element).addClass('error');
            },
            unhighlight: function (element) {
                $(element).removeClass('error');
            },
            submitHandler: function (form) {
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LdgRcEUAAAAAA_IU_hje5oXkgVtn30bcKNVk1u3', { action: 'homepage' }).then(function (token) {
                        $('#landing-contact-grecaptcha').val(token);

                        $.ajax({
                            type: 'POST',
                            dataType: 'json',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            url: formUrl,
                            cache: false,
                            data: JSON.stringify(MOMB.fn_serialize_to_JSON($contactForm)),
                            success: function (data) {
                                if (data.MessageId != null) {
                                    $contactForm.validate().resetForm();
                                    $contactForm[0].reset();
                                    $contactForm.find('.error').removeClass('error');
                                    $formNotify.html(`
                                        <div class="alert alert-success" role="alert">
                                            🤗 Thank you! We'll reach out to you ass soon as possible.
                                        </div>
                                    `);
                                } else {
                                    $formNotify.html(`                                               
                                        <div class="alert alert-danger" role="alert">
                                            Something went wrong 😟. Please try again later or via phone/email. Thank you!
                                        </div>
                                    `);
                                }
                            },
                            error: function (XMLHttpRequest, textStatus, errorThrown) {
                                $formNotify.html(`                                               
                                    <div class="alert alert-danger" role="alert">
                                        Something went wrong 😟. Please try again later or via phone/email. Thank you!
                                    </div>
                                `);
                            }
                        });
                    });
                });

            },
            invalidHandler: function (event, validator) {
                $formNotify.html(`
                    <div class="alert alert-warning" role="alert">
                        Please fill in all the fields. Thank you!
                    </div>
                `);
            }
        });
    }

    MOMB.fn_serialize_to_JSON = function (form) {
        const serializedArray = form.serializeArray();
        const serializedObject = {};

        $(serializedArray).each(function (index, obj) {
            serializedObject[obj.name] = obj.value;
        });

        return serializedObject;
    }

    // Window on Load
    $(window).on("load", function () {
        // MOMB.masonry(),
        MOMB.PreLoad();
    });
    // Document on Ready
    $(document).ready(function () {
        MOMB.SVGbx(),
            MOMB.HeaderFixd(),
            MOMB.Counter(),
            MOMB.MenuToggleClass(),
            // MOMB.Gallery(),
            MOMB.HeaderHeight(),
            MOMB.ProgressBar(),
            // MOMB.CountTimer(),
            MOMB.MegaMenu(),
            // MOMB.typedbox(),
            // MOMB.pieChart(),
            // MOMB.masonry(),
            MOMB.wowAni(),
            MOMB.one_page(),
            // MOMB.Owl();
            // MOMB.pricing_plans(),
            MOMB.pricing_calculator(),
            MOMB.contactForm()
    });

    // Document on Scrool
    $(window).scroll(function () {
        MOMB.ProgressBar(),
            MOMB.HeaderFixd();
    });

    // Window on Resize
    $(window).resize(function () {
        MOMB.HeaderHeight();
    });

})(jQuery);